import logo from './logo.svg';
import './App.css';
import Navbar from './Navbar';
import Example from './Example';
import People from './components/People';
import TestControls from './components/TestControls';
import Card from './components/Card';
import Clock from './Clock'
import "./styles.css";

function App() {
  return (
    <div>
            
      <Example />
      {/* <Clock />
      <Card />
      <TestControls/>      
      <People />
  */}
    </div>
  
  );
}

export default App;
